<script setup lang="ts">
const props = defineProps({
  alt: String,
  src: String,
  imageClass: String,
  preload: Boolean,
  size: {
    default: 'sm',
    type: String
  },
  loading: {
    default: 'lazy',
    type: String
  }
})

const sizes = computed(() => {
  if (props.size.length > 12 ) {
    return props.size;
  }
  if (props.size === 'xs') {
    return '100px';
  }
  if (props.size === 'sm') {
    return '100vw md:20vw';
  }
  if (props.size === 'full') {
    return 'md:300px lg:400px xl:100vw';
  }
  if (props.size === 'xl') {
    return '300px sm:100vw md:500px lg:900px';
  }
  if (props.size === 'lg') {
    return '50vw xl:50vw';
  }
  if (props.size === 'md') {
    return '300px lg:500px';
  }
  return '400px md:500px lg:300px 2xl:600px';
})

const emit = defineEmits([
  'click'
])

</script>

<template>
  <NuxtImg
      width="100%"
      height="100%"
      :preload="preload"
      :loading="loading"
      @click.self="emit('click')"
      :placeholder="loading === 'lazy' ? [50, 25, 75, 5] : false"
      :sizes="sizes"
      legacy-format="webp"
      provider="static"
      :alt="alt"
      :src="src"
      :class="imageClass"
  />
</template>

<style scoped>

</style>